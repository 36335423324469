
.logo-svg-animation{
    width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
  .log-container {
    position: relative;
    width: 200px;
    height: 240px;
    transform:scaleX(-1);
    > div {
      position: absolute;
      width: 100%;
      height: 100%;
      > svg {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      &.right-part {
        animation: rotate-180 3s infinite;
        animation-delay: 0.3s;
        > svg {
          right: 0px;
        }
      }
      &.left-part {
        animation: rotate180 3s infinite;
        animation-delay: 0.3s;
        > svg {
          left: 0px;
        }
      }
    }
  }

}
.normal-page-loader {
    position: fixed;
      width: 100vw;
      height: 80vh;
      margin-top: 10vh;
      top: 0;
      left: 0;
    
      // @media (max-width: $breakpoint-tablet) {
      //   height: calc(100vh - 315px);
      // }
    
      // @media (min-width: $breakpoint-tablet) {
      //   height: calc(100vh - 225px);
      // }
}

@keyframes rotate-180 {
  0%,
  30% {
    transform: rotate(0);
  }
  60% {
    transform: rotate(-180deg);
  }
  90% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes rotate180 {
  0%,
  30% {
    transform: rotate(0);
  }
  60% {
    transform: rotate(180deg);
  }
  90% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
