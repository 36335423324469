@font-face {
    font-family: 'Manrope-ExtraLight';
    src: url('./fonts/Manrope/Manrope-ExtraLight.ttf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Light';
    src: url('./fonts/Manrope/Manrope-Light.ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Regular';
    src: url('./fonts/Manrope/Manrope-Regular.ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Medium';
    src: url('./fonts/Manrope/Manrope-Medium.ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-SemiBold';
    src: url('./fonts/Manrope/Manrope-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Bold';
    src: url('./fonts/Manrope/Manrope-Bold.ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-ExtraBold';
    src: url('./fonts/Manrope/Manrope-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

html,
body,
* {
    font-family: 'Manrope-Regular', Arial, Helvetica, sans-serif;
    font-size: 1rem;
}

.link-text {
    font-family: 'Manrope-Regular', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    text-decoration: none;

    &:hover {
        color: inherit;
    }
}

.text-align-center {
    text-align: center;
}

.big-title {
    font-family: 'Manrope-Regular', sans-serif;
    font-weight: 400;
    font-size: 2.5rem;
}
.big-title-light {
    font-family: 'Manrope-Light', sans-serif;
    font-weight: 300;
   
    font-size: 33.18px;
line-height: 45px;
}
.medium-title-light{
    font-family: 'Manrope-Light', sans-serif;
    font-weight: 300;
    font-size: 27.65px;
line-height: 38px;
}

.medium-title {
    font-family: 'Manrope-Regular', sans-serif;
    font-weight: 400;
    font-size: 1.875rem;
    line-height: 2.2rem;
}

.body-text {
    font-family: 'Manrope-Regular', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
}
.body-text-light{
    font-family: 'Manrope-Light', sans-serif;
    font-weight: 300;
    font-size: 13.3px;
line-height: 18px;
}

.body-text-small {
    font-family: 'Manrope-Regular', sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.125rem;
}

.bold {
    font-family: 'Manrope-Bold', sans-serif;
    font-weight: 700;
}

@media screen and (max-width: 1280px) {
    .big-title {
        font-size: 2rem;
    }

    .medium-title {
        font-size: 1.5rem;
    }

    .body-text,
    .link-text {
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 768px) {
    .body-text {
        line-height: 1.3rem;
    }

    .body-text-small {
        font-size: 0.7rem;
        line-height: 0.875rem;
    }
}