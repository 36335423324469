/* COLORS */
.color-yellow {
    color: $yellow;
}

.color-blue {
    color: $blue;

    &:hover {
        color: $blue;
    }
}

.color-white {
    color: $white;
}

.color-gray {
    color: $gray;
}

.color-black {
    color: $black;
}

.color-red {
    color: $red;
}

.color-purple {
    color: $purple;
}

/* POSITION */
.pos-relative {
    position: relative;
}

/* DISPLAY */
.hidden {
    display: none !important;
}

.d-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.d-flex-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.justify-content-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.align-items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

#rcc-confirm-button {
    background: #F0F7FF !important;
    /* new raised */

    box-shadow: -5px -5px 10px #FFFFFF, 2px 2px 10px rgba(199, 218, 255, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(199, 218, 255, 0.5) !important;
    border-radius: 100px !important;
    color: $blue !important;
    padding: 10px 20px !important;
}

/* SIZING */
.w100 {
    width: 100%;
}

.w70 {
    width: 70%;
}

.h100 {
    height: 100%;
    overflow-y: hidden;
}

.h100vh,
#root {
    height: 100vh;
}

/* SPACING */
.mt-1 {
    margin-top: 1rem !important;
}

.mt-15 {
    margin-top: 1.5rem !important;
}


.mt-2 {
    margin-top: 2rem !important;
}

.mt-3 {
    margin-top: 3rem !important;
}

.mt-4 {
    margin-top: 4rem !important;
}

.mt-5 {
    margin-top: 5rem !important;
}

.mt-6 {
    margin-top: 6rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-3 {
    margin-bottom: 3rem !important;
}

.mb-4 {
    margin-bottom: 4rem !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

/*menu section*/

.desk {
    display: block;

    .link-text {
        margin-right: 27px;
        padding-right: 27px;
        border-right: 1px solid blue;

        &:last-child {
            border-right: none;
            margin-right: 0;
            padding-right: 0;
        }
    }
}

.claire-logo-m {
    &.mobile {
        display: none;
    }
}

.hide {
    display: none;
}




/* INPUT */
.email-subscribe-input {
    position: relative;
    width: 555px;

    input {
        padding: 0;
        color: #212121;
        padding-left: 2.5rem;
        font-family: 'Manrope-Regular';
        font-weight: 400;
        font-size: 1rem;
        line-height: 25px;
        width: 100%;
        height: 3.1rem;
        border-color: transparent;
        border: 1px;

        background: linear-gradient(90deg, #F1F8FF 2%, #F5FAFF 100%);
        /* new sunken */
        border-radius: 105px;
        box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3), 0px -1px 2px rgba(169, 166, 169, 0.2), inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 1px 1px 3px #C7DAFF;
    }

    input:focus-visible {
        outline: none;
    }

    .submit {
        border: none;
        position: absolute;
        width: 200px;
        height: 3.1rem;

        background: linear-gradient(90deg, #F1F8FF 2%, #F5FAFF 100%);
        /* new raised */

        box-shadow: -5px -5px 10px #FFFFFF, 2px 2px 10px rgba(199, 218, 255, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(199, 218, 255, 0.5);
        border-radius: 105px;
        right: 0;
        top: 50%;

        -webkit-transform: translate(2.8rem, -50%);
        -ms-transform: translate(2.8rem, -50%);
        transform: translate(2.8rem, -50%);
        font-family: 'Manrope-SemiBold';
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        text-align: center;
        color: $blue;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;

        span {
            @extend %gradient-text;
        }

        &:disabled::after {
            content: '';
            cursor: default;
            position: absolute;
            width: 96%;
            height: 100%;


            border-radius: 105px;
        }
    }
}

.inline-page-loader {
    position: relative;
    margin-top: 5vh;
}

.consent-checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;

    input[type='checkbox'] {
        content: '';
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none !important;
        width: 30px;
        height: 30px;
        position: relative;
        border-radius: 100px;
        margin-right: 1rem;
        cursor: pointer;
        background: $gray2;
        -webkit-box-shadow: -5px -5px 10px #FFFFFF, 2px 2px 10px rgba(199, 218, 255, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(199, 218, 255, 0.5);
        box-shadow: -5px -5px 10px #FFFFFF, 2px 2px 10px rgba(199, 218, 255, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(199, 218, 255, 0.5);

        &:checked {
            -webkit-box-shadow: 0px -1px 2px rgba(169, 166, 169, 0.2), 1px 1px 2px rgba(255, 255, 255, 0.3), inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset 2px 2px 5px rgba(169, 166, 169, 0.9);
            box-shadow: 0px -1px 2px rgba(169, 166, 169, 0.2), 1px 1px 2px rgba(255, 255, 255, 0.3), inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset 2px 2px 5px rgba(169, 166, 169, 0.9);

            &::after {
                content: '';
                -moz-appearance: none;
                appearance: none;
                -webkit-appearance: none !important;
                width: 13px;
                height: 13px;
                position: absolute;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                background-color: $blue;
            }
        }
    }

    &.dark {
        input[type='checkbox'] {
            background-color: $black;
            -webkit-box-shadow: -5px -5px 15px rgba(193, 193, 193, 0.25), 5px 5px 13px rgba(0, 0, 0, 0.5), inset 1px 1px 2px rgba(83, 82, 82, 0.8), inset -1px -1px 3px #0D0E0F;
            box-shadow: -5px -5px 15px rgba(193, 193, 193, 0.25), 5px 5px 13px rgba(0, 0, 0, 0.5), inset 1px 1px 2px rgba(83, 82, 82, 0.8), inset -1px -1px 3px #0D0E0F;

            &:checked::after {
                background-color: $yellow;
            }
        }
    }

}

.input-wrapper {
    input {
        border: none;
        background: -webkit-gradient(linear, left top, right top, color-stop(2%, #F1F8FF), to(#F5FAFF));
        background: -o-linear-gradient(left, #F1F8FF 2%, #F5FAFF 100%);
        background: linear-gradient(90deg, #F1F8FF 2%, #F5FAFF 100%);
        -webkit-box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3), 0px -1px 2px rgba(169, 166, 169, 0.2), inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 1px 1px 3px #C7DAFF;
        box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3), 0px -1px 2px rgba(169, 166, 169, 0.2), inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 1px 1px 3px #C7DAFF;
        border-radius: 100px;
        width: 100%;
        height: 3.75rem;
        padding-left: 2rem;
        color: $black;
        font-size: 16px;
        line-height: 25px;
        font-family: 'Manrope-Regular';

        &:focus-visible {
            outline: none;
        }
    }
}

.radio-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .radio-element {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 2rem;
    }

    input[type='radio'] {
        content: '';
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none !important;
        width: 30px;
        height: 30px;
        position: relative;
        background: $gray2;
        -webkit-box-shadow: -5px -5px 10px #FFFFFF, 5px 5px 13px #CACACA, inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(194, 190, 194, 0.5);
        box-shadow: -5px -5px 10px #FFFFFF, 5px 5px 13px #CACACA, inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(194, 190, 194, 0.5);
        border-radius: 50%;
        margin-right: 0.8rem;

        &:checked {
            -webkit-box-shadow: 0px -1px 2px rgba(169, 166, 169, 0.2), 1px 1px 2px rgba(255, 255, 255, 0.3), inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset 2px 2px 5px rgba(169, 166, 169, 0.9);
            box-shadow: 0px -1px 2px rgba(169, 166, 169, 0.2), 1px 1px 2px rgba(255, 255, 255, 0.3), inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset 2px 2px 5px rgba(169, 166, 169, 0.9);

            &::after {
                content: '';
                -moz-appearance: none;
                appearance: none;
                -webkit-appearance: none !important;
                width: 13px;
                height: 13px;
                position: absolute;
                background-color: $blue;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }
    }
}

/* OTHERS */

/*cookies*/

.root__TFKT8.sizer__JgPZ9 {
    background: linear-gradient(90deg, #F1F8FF 2%, #F5FAFF 100%) !important;

    #banner-description {
        color: $black !important;
    }

    #banner-title {
        color: $black !important;
    }

    .buttons__kAnBF {
        button {
            box-shadow: -5px -5px 10px #FFFFFF, 5px 5px 13px #CACACA, inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(194, 190, 194, 0.5) !important;
            border-radius: 100px !important;

            span {
                background: linear-gradient(94.94deg, #A347FF 0%, #432CD2 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }

        }
    }

}

#lanyard_root {
    display: none;
}

.page-section {
    height: 100vh;
    width: 100%;
    /*     -webkit-transform: translate(0, 0) !important;
    -ms-transform           : translate(0, 0) !important;
    transform               : translate(0, 0) !important; */
}

/* .pin-spacer {
    height: 100vh !important;
} */

.error_msg {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    margin-top: 11px;
}

.has-error>input {
    border: 1px solid red;
    ;
}

body {
    &.leader-detail {
        .link-text {
            &.leader {
                position: absolute;
                top: 16px;
            }


        }

        .big-title-light {
            opacity: 0;
        }

        .back {
            display: flex;
            cursor: pointer;

            img {
                margin-right: 14px;
            }
        }
    }

    .back {
        display: none;
    }
}

.section-1,
.section-2 {
    position: relative;
    top: 0;
    width: 100%;
}

.section-2,
.section-3,
.sub-section1,
.sub-section2 {
    opacity: 1;
}

.sub-section1,
.sub-section2 {
    position: relative;

    width: 100%;
    padding-top: 0px;
}

.sub-section1 {
    padding-bottom: 80px;
}

.section-3 {
    position: relative;

    .footer-wrapper {
        position: relative;


        /* .footer-links>a {
            color: $white;
        }*/
    }
}

.infos {


    .title {

        h2 {
            font-family: 'Manrope-Light';
            font-size: 27px;
            line-height: 34px;
            text-align: center;
            margin-bottom: 2.6rem;

            /* Dark background */

            color: #252627;

            /* Text Neumorphism */

            text-shadow: -2.91034px -2.91034px 5.82069px #FFFFFF, 2.91034px 2.91034px 7.5669px #CACACA;
        }
    }

    .text {
        max-width: 880px;
        text-align: center;
        display: flex;
        font-size: 15px;
        line-height: 20px;

        p {}
    }

}


.light-background {
    /*  background-image: url("../img/jpg/light-background.jpg");*/
    background: linear-gradient(90deg, #F1F8FF 2%, #F5FAFF 100%);
}

.dark-background {
    background-image: url('../img/jpg/dark-background.jpg');
}

.header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    margin: 2.5rem auto;
    width: 100%
}

.header-normal {
    width: 100%;

    &.scroll-active {

        background: linear-gradient(180deg, #F1F8FF 51.3%, rgba(241, 248, 255, 0) 100%);

        height: 115px;
        z-index: 999;
        position: fixed;
        top: 0;
    }
}


.header-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 80%;
    margin: 0 auto
}

.video-background-wrapper video {
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}

.unmute-video {
    position: fixed;
    z-index: 1;
    bottom: 3vh;
    right: 3vw;
    z-index: 5;
    background: none;
    border: none;
    height: auto;

    &:focus-visible {
        border: none;
    }
}

.c-scrolldown {
    position: absolute;
    bottom: 15%;
    left: 0;
    right: 0;
    margin: 0 auto;
    overflow: hidden;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    flex-flow: column;

}

.c-line {
    /*   
    display: block;
    color  : $blue;
    border : 1px solid $blue;

     -webkit-animation  : scrolldown 3.5s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
    animation          : scrolldown 3.5s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;*/
    width: 1.2px;
    height: 35px;
    display: block;
    background: linear-gradient(to bottom, $blue 50%, rgba(255, 255, 255, 0) 50%);
    background-position: 0 -35px;
    background-size: 100% 200%;
    animation: scrolldown 3.2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
    animation-delay: 15s;
}

.swiper-slide:nth-child(2) {
    .c-line {
        animation-delay: 0s;
    }

    .c-text {
        animation-delay: 0s;
    }
}

.c-text {
    font-size: 14px;
    line-height: 16px;
    font-family: 'Manrope-SemiBold';
    color: $blue;

    margin-bottom: 10px;
    animation-name: fadein;
    animation-delay: 15s;
    animation-duration: 1s;
    animation-fill-mode: both;

}

@-webkit-keyframes scrolldown {
    0% {
        background-position: 0 -35px;
    }

    50% {
        background-position: 0 0;
    }

    100% {
        background-position: 0 -35px;
    }

    55% {
        background-position: 0 0;
    }

    0% {
        background-position: 0 -35px;
    }
}

@keyframes scrolldown {
    0% {
        background-position: 0 -35px;
    }

    50% {
        background-position: 0 0;
    }

    100% {
        background-position: 0 -35px;
    }

    55% {
        background-position: 0 0;
    }

    0% {
        background-position: 0 -35px;
    }
}

.slide-top {
    -webkit-animation: slide-in-bottom 2s both;
    animation: slide-in-bottom 2s both;
}

@-webkit-keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px) scale(0);
        transform: translateY(1000px) scale(0);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) scale(1);
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
}

@keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px) scale(0);
        transform: translateY(1000px) scale(0);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) scale(1);
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
}


.item {
    transform: translateY(50px);
    opacity: 1;
}

.claire-logo {
    position: relative;
}

.logo-hidden {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
}

//backer page
.mobile-scroll {
    display: none;
}

.backers-wrapper {
    margin-top: 4%;
    margin-bottom: 4%;

    &.mob-only {
        &.d-flex {
            display: none !important;
        }

    }

    &.desk-only {
        display: block;
    }

    .backer-box {
        width: 530px;
        text-align: center;
        border: 0.5px solid #000000;
        border-radius: 30px;
        padding-bottom: 40px;
        padding-right: 70px;
        padding-left: 70px;

        &:first-child {
            margin-right: 20px;
        }

        .b-description {
            text-align: left;
            margin-bottom: 10%;
        }

        .pasaca {
            margin-top: 33px;
            margin-bottom: 34px;
        }

        .liveperson {
            margin-top: 50px;
            margin-bottom: 52px;
        }

        .view-backers-cta {
            position: absolute;
            bottom: 40px;
            left: 50%;
            transform: translate(-50%);
            font-size: 16px;
            font-family: 'Manrope-SemiBold', sans-serif;
        }
    }
}

.desk-job {
    display: block;
}

.mob-job {
    display: none;
}

.purple-separator {
    height: 14px;
    width: 1px;

    background: linear-gradient(94.94deg, #A347FF 0%, #432CD2 100%);
    margin-top: 6px;

    &:last-child {
        background: none;
    }
}

//animation for backers
.no-animation {

    .text-animation {
        animation-duration: 1s;
        animation-fill-mode: both;
        transition: none !important;
        animation: none !important;
    }
}

.text-animation {
    animation-duration: 1s;
    animation-fill-mode: both;
}


.fadeInBottom {
    animation-name: fadeInBottom;
}

.sequence2 {
    animation-delay: 1s;
}

.sequence3 {
    animation-delay: 2.1s;
}

.sequence4 {
    animation-delay: 2.2s;
}

.sequence5 {
    animation-delay: 2.3s;
}

.sequence6 {
    animation-delay: 2.4s;
}

.sequence7 {
    animation-delay: 2.5s;
}

.sequence8 {
    animation-delay: 2.6s;
}

.sequence9 {
    animation-delay: 2.7s;
}

.sequence10 {
    animation-delay: 2.8s;
}

.sequence11 {
    animation-delay: 2.9s;
}

.sequence12 {
    animation-delay: 3s;
}

.sequence13 {
    animation-delay: 3.1s;
}

.jobs-wrapper.desk-only {
    .sequence2 {
        animation-delay: 1s;
    }

    .sequence3 {
        animation-delay: 2s;
    }

    .sequence4 {
        animation-delay: 3s;
    }

    .sequence5 {
        animation-delay: 4s;
    }

    .sequence6 {
        animation-delay: 5s;
    }

    .sequence7 {
        animation-delay: 6s;
    }
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(100%);
    }

    to {
        opacity: 1
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


/* .big-title.fade-color,
#consent-checkbox.fade-color {
    -webkit-animation: fadeIn 2.2s linear forwards;
    animation: fadeIn 2.2s linear forwards;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        color: $yellow;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        color: $yellow;
    }
} */
.error-404 {

    .page-wrapper {
        margin-top: 14%;
    }

    .response-404 {
        h1 {
            font-family: 'Manrope-Regular';

            font-weight: 300;
            font-size: 100px;
            line-height: 137px;
            margin-bottom: 10px;
        }

        .body-text {
            font-size: 23.04px;
            line-height: 31px;

        }

        .link-text {
            margin-top: 100px;

            .body-text {
                font-size: 13px;
                line-height: 18px;
            }
        }

    }
}


.shadow {
    pointer-events: none;
    border-radius: 50%;
    position: fixed;
    width: 18%;
    height: 25%;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: $purple;
    -webkit-filter: blur(180px);
    filter: blur(180px);
}

.shadow-fixed {
    pointer-events: none;
    border-radius: 50%;
    position: fixed;
    width: 18%;
    height: 25%;
    left: 98%;
    top: 24%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /*background       : url("../img/svg/gradient-purple.svg");*/
    -webkit-filter: blur(54px);

    filter: blur(54px);
    background-color: #ECEEFF;
}


.dark-container {
    background-color: rgb(46, 43, 46);
    padding: 2rem;
}

/*.footer-wrapper {
    width     : 75%;
    margin    : auto;
    border-top: 1px solid $white;
}*/

.footer-main {
    padding: 1rem 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: $white;

    .body-text {

        br {
            display: none;
        }

        span.reserved {
            margin-left: 4px;
        }
    }

}

.terms-of-use {
    .t-use {
        font-weight: bold;
        font-family: 'Manrope-Bold';
    }
}

.privacy-policy {
    .privacy-p {
        font-weight: bold;
        font-family: 'Manrope-Bold';
    }
}


.footer-links>a:not(:first-child) {
    margin-left: 2rem;
}

.leader-wrapper {
    margin: 3% auto;
    /*width              : 80vw;*/

    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 5rem;

    &.desk-only {
        display: grid;
        padding-left: 15px;
    }

    &.mob-only {
        display: none;
    }

    .leader-cnt {
        display: flex;

        flex-direction: column;

        .leader-logo {
            img {
                height: 99px;
                margin-bottom: 30px;
                -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
                filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
            }
        }

        span.body-text {
            font-size: 13px;
            line-height: 18px;
        }

        .job-title {
            .medium-title {
                font-family: 'Manrope-Light';
                font-size: 22px;

            }
        }
    }
}

.leadership-wrapper {
    margin-top: 4%;
    margin-bottom: 3%;


    .logos {
        justify-content: center;

        span {
            margin-right: 0;
            padding: 0 30px;


            &:last-child {
                margin-right: 0;
            }


            font-family : 'Manrope-SemiBold';
            background : linear-gradient(94.94deg, #A347FF 0%, #432CD2 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip : text;
            text-fill-color : transparent;
            font-size : 19.2px;
            line-height : 26px;
        }
    }



    .leader-detail-inner {
        justify-content: center;
        align-items: center;

        img {
            height: 100%;
            width: 220px;
            padding-left: 15px;
            -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
            filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
        }

        .lead-infos {
            max-width: 530px;
            margin-left: 100px;

            .leader-name {
                p {
                    margin-bottom: 50px;

                    br {
                        display: none;
                    }
                }
            }

            .lead-detail-desc {
                border-left: 0.5px solid #212121;
                padding: 10px 0 10px 30px;
            }
        }
    }

    .logos {
        margin-top: 10%;
    }

}


body {

    &.hp2-pg {
        .hp-bold {
            font-weight: bold;
            font-family: 'Manrope-Bold';
        }
    }

    &.backers-pg {
        .bakers-bold {
            font-weight: bold;
            font-family: 'Manrope-Bold';
        }

    }

    &.who-pg {
        .who-bold {
            font-weight: bold;
            font-family: 'Manrope-Bold';
        }

        .border-left {
            align-items: flex-start;
        }

        .page-wrapper {
            width: 100% !important;
            margin-top: 25vh !important;
        }

        .shadow-fixed {
            background: #B387FA;
            filter: blur(200px);
        }

        .block-3 .mob-only {
            display: none;
        }

        .block-4.health .floating-box-right ul li {
            text-transform: uppercase;
        }

        .graphic .mob-only {
            display: none;
        }

    }

    &.leaderships-pg {
        .lead-bold {
            font-weight: bold;
            font-family: 'Manrope-Bold';
        }

    }

    &.leader-detail {
        .lead-bold {
            font-weight: bold;
            font-family: 'Manrope-Bold';
        }


    }

    &.work-pg {
        .work-bold {
            font-weight: bold;
            font-family: 'Manrope-Bold';
        }

        .no-job {
            text-align: center;
            margin-top: 130px;

            p {
                font-size: 18px;
                line-height: 24px;

                &.bold {
                    display: block;
                    margin-bottom: 20px;
                    font-size: 23px;
                    line-height: 31px;
                }
            }

        }
    }
}

/*
contact-form*/

.contact-us {
justify-content: space-around;
    .get_in_touch {
        max-width: 435px;

        p {
            font-size: 23.04px;
            line-height: 31px;
            font-family: 'Manrope-Light';
        }

        h2 {
            font-size: 39.81px;
            line-height: 54px;
            margin-bottom: 20px;
        }
    }

    .thankyou {
        max-width: 420px;
        background: linear-gradient(90deg, #F1F8FF 2%, #F5FAFF 100%);
        display: none;
        /* new sunken */

        box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3), 0px -1px 2px rgba(169, 166, 169, 0.2), inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 1px 1px 3px #C7DAFF;
        border-radius: 30px;
        text-align: center;

        p {
            font-size: 18px;
            line-height: 25px;
            font-family: 'Manrope-Light';
            margin-bottom: 177px;
        }

        h2 {
            font-size: 39.81px;
            margin-top: 177px;
            line-height: 54px;
            font-family: 'Manrope-Light';
            margin-bottom: 20px;
            color: $blue;
        }
    }

    .contact_form {
        max-width: 420px;

        input::-webkit-contacts-auto-fill-button {
            visibility: hidden;
            display: none !important;
            pointer-events: none;
            position: absolute;
            right: 0;
        }

        .error_msg {
            margin-bottom: 12px;
        }

        input::placeholder {
            color: #9F9F9F;
            font-size: 16px;
            line-height: 25px;
        }

        input {
            font-size: 16px;
            line-height: 25px;

            &:focus-visible {
                outline: none;
            }

        }

        .input-wrapper {

            margin-bottom: 18px;
        }

        textarea {
            height: 173px;
            resize: none;
            border-radius: 30px;
            width: 420px;
            padding-left: 2rem;
            background: linear-gradient(90deg, #F1F8FF 2%, #F5FAFF 100%);
            box-shadow: 1px 1px 2px rgb(255 255 255 / 30%), 0px -1px 2px rgb(169 166 169 / 20%), inset -2px -2px 4px rgb(255 255 255 / 90%), inset 1px 1px 3px #c7daff;
            border: none;
            padding-top: 14px;
            padding-right: 2rem;

            &::placeholder {
                color: #9F9F9F;
                font-size: 16px;
                line-height: 25px;
            }

            &:focus-visible {
                outline: none;
            }

        }

        .submit-form {

            .primary-cta {
                justify-content: center;
                width: 236px;
                height: 60px;
                margin-top: 18px;
            }
        }
    }
}

/*who we serve*/
.back-description {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 32px;



    .desc {
        display: flex;
        align-items: center;

        span {
            width: 53px;
            border-top: 0.8px solid #000000;
            height: 0;
            margin-right: 10px;
        }

        p {
            font-family: 'Manrope-Light';

            font-size: 14.4px;
            line-height: 20px;
        }
    }
}

.block-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 111px;

    h1 {
        font-family: 'Manrope-Regular';

        font-size: 48px;
        line-height: 56px;
        margin-bottom: 40px;
    }

    p {
        font-family: 'Manrope-Light';
        font-size: 18px;
        line-height: 25px;
        max-width: 530px;
    }

    .left-txt {
        max-width: 555px;
        min-height: 280px;
    }

    .right-img {
        img {
            width: 430px;

        }
    }
}


.block-2 {
    margin-bottom: 130px;

    &.digital {
        margin-bottom: 300px;
    }

    .box-purple {
        width: 80%;
        margin: 0 auto;

        .floating-box {
            width: 520px;
            background: #EEEFFF;
            padding: 90px;

            position: absolute;
            top: -260px;
            text-align: justify;


            p {
                font-family: 'Manrope-Light';

                font-size: 14.8369px;
                line-height: 20px;

            }


        }

        .floating-box-bottom {
            width: 712px;
            ;
            background: #EEEFFF;
            padding: 80px 92px;

            position: absolute;
            top: -100px;
            text-align: justify;


            p {
                font-family: 'Manrope-Light';

                font-size: 14.4px;
                line-height: 20px;

            }

        }
    }


    img {
        width: 100vw;
    }

}

.block-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 90px;

    .left-img {
        margin-right: 57px;

        img {
            width: 384px;
        }
    }

    ul {
        list-style: unset;
        list-style-position: inside;

        li {
            font-family: 'Manrope-Light';

            font-size: 14.4px;
            line-height: 20px;
            margin-bottom: 8px;
        }
    }

    .right-text {
        max-width: 640px;

        h3 {
            font-family: 'Manrope-Light';

            margin-bottom: 32px;
            font-size: 32px;
            line-height: 44px;


            color: #432CD2;
        }

        p {
            font-family: 'Manrope-Light';

            font-size: 14.4px;
            line-height: 20px;
            text-align: justify;

        }

        .text-1 {
            margin-bottom: 46px;
        }
    }

    .right-img {
        width: 384px;

        img {
            width: 100%;
        }
    }

    .left-text {
        max-width: 504px;
        margin-right: 80px;

        h3 {
            font-family: 'Manrope-Light';

            margin-bottom: 32px;
            font-size: 32px;
            line-height: 44px;


            color: #432CD2;
        }

        p {
            font-family: 'Manrope-Light';

            font-size: 14.4px;
            line-height: 20px;
            text-align: justify;

        }

        .text-1 {
            margin-bottom: 46px;
        }
    }

}

.block-4 {
    margin-bottom: 73px;

    &.switched {
        margin-bottom: 100px;
    }

    .health-img {
        text-align: right;
    }

    .purple-box {
        width: 80%;
        margin: 0 auto;
        position: relative;
    }

    img {
        width: 70vw;
    }


    .floating-box-right {
        background: #EEEFFF;
        width: 691px;
        padding: 80px;
        position: absolute;
        top: -80px;
        right: 0;

        &.health {
            width: 579px;
        }

        p {
            font-family: 'Manrope-Light';
            font-size: 32px;
            line-height: 44px;
        }

        li {

            font-family: 'Manrope-Regular';
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 14px;
        }

        h4 {
            font-family: 'Manrope-Light';
            font-size: 48px;
            line-height: 66px;
            margin-bottom: 38px;
        }
    }

    .floating-box-left {
        background: #EEEFFF;
        width: 651px;
        padding: 80px;
        position: absolute;
        top: -80px;
        left: 0;

        p {
            font-family: 'Manrope-Light';
            font-size: 32px;
            line-height: 44px;
        }

        li {

            font-family: 'Manrope-Regular';
            font-size: 14.4px;
            line-height: 20px;
            margin-bottom: 24px;
        }

        h4 {
            font-family: 'Manrope-Light';
            font-size: 48px;
            line-height: 66px;
            margin-bottom: 38px;
        }
    }
}

.block-5 {
    display: flex;
    justify-content: center;

    width: 80%;
    margin: 0 auto;
    margin-bottom: 100px;

    .box {
        max-width: 248px;
        text-align: center;
        display: flex;
        flex-flow: column;
        align-items: center;
        margin-right: 67px;

        &:last-child {
            margin-right: 0;
        }

    }

    &.health {
        .title {
            height: 50px;
        }
    }

    .title {
        height: 75px;
        margin-bottom: 20px;
    }

    h4 {
        font-family: 'Manrope-SemiBold';

        font-size: 18.432px;
        line-height: 25px;

        letter-spacing: 0.2em;
        text-transform: uppercase;

    }

    .separator {
        width: 34px;
        height: 0px;
        border-top: 1.6px solid #000000;
        display: block;
        margin-bottom: 20px;
    }

    .box-desc {
        text-align: center;

        p {
            font-size: 14.4px;
            line-height: 20px;
            font-family: 'Manrope-Light';
        }

    }
}

.graphic {
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 140px;

    h2 {
        font-size: 48px;
        line-height: 66px;
        font-family: 'Manrope-Light';
        margin-bottom: 50px;
        color: $blue;
        text-align: center;
    }

    img {
        width: 100%;
    }
}

.jobs-wrapper {
    margin: 5% auto;
    /*width              : 80vw;*/

    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 5rem;

    &.desk-only {
        display: grid;
        padding-bottom: 70px;
    }

    &.mob-only {
        display: none;
    }

}

.submit-job {


    .primary-cta {
        padding: 1.3rem 2.4rem;
    }
}

.form-job-data {
    justify-content: space-between;

    .left-cnt {
        width: 420px;
        margin-right: 129px;
        margin-left: 4px;

        .input-wrapper {
            &:first-child {
                margin-top: 0 !important;
            }
        }
    }

    .primary-cta {
        padding: 13px 24px;
        width: 200px;
    }

    span {
        font-size: 16px;
        width: 100%;
    }

    .resume-csv {
        margin-bottom: 60px;
    }

    .input-title {
        display: block;
        margin-top: 30px;
        margin-bottom: 10px;

    }
}

.selection {
    background: linear-gradient(90deg, #F1F8FF 2%, #F5FAFF 100%);
    /* new raised */

    box-shadow: -5px -5px 10px #FFFFFF, 2px 2px 10px rgba(199, 218, 255, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(199, 218, 255, 0.5);
    border-radius: 100px;
    border: none;
    width: 420px;
    padding: 14.17px 43px;
    color: $gray;
    font-size: 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../img/svg/arrow-select.svg');

    background-size: 16px;
    background-position: calc(100% - 20px) 20px;
    background-repeat: no-repeat;
    margin-top: 15px;

}

.job-item {
    width: 15rem;
    margin-left: 2rem;
    margin-bottom: 2rem;

    &.job-type-empty {
        margin-top: 1.5rem;
    }

    .job-title {

        .medium-title {
            font-family: 'Manrope-Light';
            font-size: 27.65px;
            line-height: 38px;

        }

    }
}

.border-left {
    border-left: 0.5px solid $black;
    height: 100%;
    max-height: 8rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 20px;
}

.view-job-cta {
    display: block;
    margin-top: 2rem;
    font-size: 16px;
    font-family: 'Manrope-SemiBold', sans-serif;
}

.claire-logo img {
    width: 10.8rem;
}

%gradient-text {
    background: -o-linear-gradient(355.06deg, #A347FF 0%, #432CD2 100%);
    background: linear-gradient(94.94deg, #A347FF 0%, #432CD2 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.gradient-text {
    @extend %gradient-text;
}

.primary-cta {
    border: none;
    background: -webkit-gradient(linear, left top, right top, color-stop(2%, #F1F8FF), to(#F5FAFF));
    background: -o-linear-gradient(left, #F1F8FF 2%, #F5FAFF 100%);
    background: linear-gradient(90deg, #F1F8FF 2%, #F5FAFF 100%);
    -webkit-box-shadow: -5px -5px 10px #FFFFFF, 2px 2px 10px rgba(199, 218, 255, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(199, 218, 255, 0.5);
    box-shadow: -5px -5px 10px #FFFFFF, 2px 2px 10px rgba(199, 218, 255, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(199, 218, 255, 0.5);
    border-radius: 100px;
    cursor: pointer;
    padding: 0.8rem 2.4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    font-size: 16px;
    font-family: 'Manrope-SemiBold', sans-serif;

    &:disabled {
        opacity: 0.5;
        cursor: auto;
    }

    .color-blue {
        @extend %gradient-text;
    }

    &.apply {
        height: 60px;

        padding: 0.8rem;
        width: 150px;
        display: block;

        span {
            @extend %gradient-text;
            line-height: 22px;
            font-size: 1rem;
            font-weight: 600;
        }

    }
}

.upload-icon {
    width: 20px;
    height: 20px;
}

.master-content {
    &:not(.home) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: auto;
        min-height: 100vh;

        /* .header-wrapper {

            width   : calc(100% - 10rem - 10rem);
            margin  : 2.5rem auto 1rem;
            
        }*/
        .header-normal,
        .header-cnt {
            width: 100%;
            height: 160px;
            background: linear-gradient(180deg, #F1F8FF 60%, rgba(241, 248, 255, 0) 100%);
            z-index: 999;
            position: fixed;
            top: 0;
        }

        .page-wrapper {
            width: 80%;
            margin: auto 0;
            margin-top: 10%;
            position: relative;
        }

        .mobile-shadow {
            display: none;
        }

        .page-logo {
            display: block;
        }

        .hp-logo {
            display: none;
        }


    }

    .page-logo {
        display: none;
    }

    .hp-logo {
        display: block;
    }

    .page-wrapper {
        overflow-x: hidden;
    }

    .footer-wrapper {
        width: 80%;
        opacity: 1;
        margin: auto auto 0;

        border-top: 1px solid $black;


        .body-text {
            font-size: 13.3px;
            line-height: 18px;
            color: $black;
        }

        .link-text {
            font-size: 13.3px;
            line-height: 18px;
            color: $black;

            &.t-contact {
                font-family: 'Manrope-Bold';



                color: $blue;
            }
        }
    }

    &.job-detail {
        .page-wrapper {
            margin-top: 6%;
        }
    }
}

.terms {
    margin-bottom: 40px;

    .terms-cnt {
        width: 670px;
        margin: 0 auto;
        margin-bottom: 50px;

        .accordion {
            margin-top: 4rem;
        }

        .accordion-item,
        .accordion-header,
        .accordion-button {
            background: none;
            border: none;
            border-radius: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .accordion-button {
            padding: 0;

            &::after {
                background-color: $gray2;
                background-position: center;
                background-image: url('../img/svg/down-arrow.svg');
                background-size: 1rem;
                box-shadow: -5px -5px 10px #FFFFFF, 2px 2px 10px rgba(199, 218, 255, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(199, 218, 255, 0.5);
                border-radius: 50%;
                -webkit-box-shadow: -5px -5px 10px #FFFFFF, 2px 2px 10px rgba(199, 218, 255, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(199, 218, 255, 0.5);
                width: 3rem;
                height: 3rem;
            }

            &:not(.collapsed) {
                color: inherit;

                &::after {
                    -webkit-box-shadow: 5px 5px 10px #ffffff, -2px -2px 10px rgb(199 218 255 / 90%), inset -1px -1px 2px rgb(255 255 255 / 30%), inset 1px 1px 2px rgb(199 218 255 / 50%);
                    box-shadow: 5px 5px 10px #ffffff, -2px -2px 10px rgb(199 218 255 / 90%), inset -1px -1px 2px rgb(255 255 255 / 30%), inset 1px 1px 2px rgb(199 218 255 / 50%);
                }
            }
        }

        .accordion-item {
            padding: 1.5rem 0;
            border-bottom: 0.5px solid $black;
            margin: 0 30px;

            &:first-child {
                border-top: 0.5px solid $black;
            }
        }

        .accordion-header {
            @extend .body-text;

            font-size: 1.25rem;
        }

        .accordion-body {
            padding: 0;
            margin-top: 1rem;
            line-height: 1.5rem;
            text-align: left;
            font-size: 16px;
            line-height: 25px;
            font-family: 'Manrope-Regular', sans-serif;
        }

    }
}

.job-details-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .head-title {
        justify-content: space-between;
    }

    .left-side {
        width: 60%;
        margin-bottom: 200px;

        .result_msg {
            font-weight: bold;
        }

        .error_msg {
            font-weight: bold;
        }

        .accordion {
            margin-top: 4rem;
        }

        .accordion-item,
        .accordion-header,
        .accordion-button {
            background: none;
            border: none;
            border-radius: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .accordion-button {
            padding: 0;

            &::after {
                background-color: $gray2;
                background-position: center;
                background-image: url('../img/svg/down-arrow.svg');
                background-size: 1rem;
                -webkit-box-shadow: -5px -5px 10px #FFFFFF, 2px 2px 10px rgba(199, 218, 255, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(199, 218, 255, 0.5);
                box-shadow: -5px -5px 10px #FFFFFF, 2px 2px 10px rgba(199, 218, 255, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(199, 218, 255, 0.5);
                border-radius: 50%;
                width: 3rem;
                height: 3rem;
            }

            &:not(.collapsed) {
                color: inherit;

                &::after {
                    -webkit-box-shadow: 5px 5px 10px #ffffff, -2px -2px 10px rgb(199 218 255 / 90%), inset -1px -1px 2px rgb(255 255 255 / 30%), inset 1px 1px 2px rgb(199 218 255 / 50%);
                    box-shadow: 5px 5px 10px #ffffff, -2px -2px 10px rgb(199 218 255 / 90%), inset -1px -1px 2px rgb(255 255 255 / 30%), inset 1px 1px 2px rgb(199 218 255 / 50%);
                }
            }
        }

        .accordion-item {
            padding: 1.5rem 0;
            border-bottom: 0.5px solid $black;

            &:first-child {
                border-top: 0.5px solid $black;
            }
        }

        .accordion-header {
            @extend .body-text;

            font-size: 1.25rem;
        }

        .accordion-body {
            padding: 0;
            margin-top: 1rem;
            line-height: 1.5rem;
            font-family: 'Manrope-Regular';
            text-align: justify;
        }

        .works-categories {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;

            &>* {
                margin-top: 1rem;
            }

            .desk-job {
                font-size: 18px;
                font-family: 'Manrope-Light';
            }
        }
    }

    .right-side {
        width: 30%;
        margin-top: 8vh;

        .primary-cta {
            margin-bottom: 15px;
            cursor: default;
        }

        // .company-details {
        //     &.body-text {
        //         //text-align: justify;
        //     }
        // }
    }
}

.title-wrapper {
    width: 70%;
}

.intro-text-mobile {
    display: none;
}

/*newhp*/




body {
    position: relative;
    height: 100%;
    background: #F1F8FF linear-gradient(90deg, #F1F8FF 2%, #F5FAFF 100%);
    font-family: 'Manrope-Regular', sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;

    &.hp2-pg {



        .master-content {
            display: block;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: inherit;
            min-height: auto;


            .footer-wrapper {
                display: block;

                width: 80%;
                margin-top: 20px;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%);
                z-index: 3;
                background: linear-gradient(0deg, #F1F8FF 50.68%, rgba(241, 248, 255, 0) 100%);

                &.hide-footer {
                    z-index: 0 !important;
                }

                .body-text {
                    font-size: 13.3px;
                    line-height: 18px;
                }

                .link-text {
                    font-size: 13.3px;
                    line-height: 18px;
                }
            }

            .page-wrapper {
                height: 100%;
                width: 100%;
                margin-top: 0;
            }

            .header-normal {
                background: none;
            }

            .mobile-hp2 {
                display: none;
            }

            .swiper {
                width: 100%;
                height: 100%;

                &.low {
                    z-index: 0;
                }
            }

            .swiper-slide {
                text-align: center;
                font-size: 18px;


                /* Center slide text vertically */
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;

                &:first-child {
                    z-index: 4;
                }

            }

            .swiper-pagination {
                opacity: 0;
            }


            .swiper-slide img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .coming-spring {
                &.medium-title {
                    font-size: 19.2px;
                    line-height: 26px;
                    font-family: 'Manrope-SemiBold';
                    margin-bottom: 20px;
                }
            }

            .slide3 {
                font-family: 'Manrope-Light';
                font-size: 27px;
                line-height: 34px;
                text-align: center;
                margin-bottom: 35px;

                /* Dark background */

                color: #252627;

                /* Text Neumorphism */

                text-shadow: -2.91034px -2.91034px 5.82069px #FFFFFF, 2.91034px 2.91034px 7.5669px #CACACA;
            }

            .email-subscribe-input {
                margin-bottom: 0px;
            }

            .consent-checkbox {
                margin-top: 40px;
            }

            .contact-us-wrapper {
                .consent-checkbox {
                    input[type=checkbox] {
                        margin-left: 0;
                        width: 105px;
                    }
                }
            }

        }


    }
}

/*end new hp*/

@media screen and (min-width: 1535px) {
    .infos {
        .title {
            h2 {
                margin-bottom: 40px;
                font-size: 40.65px;
                line-height: 52px;
            }
        }
    }

    .contact-us-wrapper {
        .consent-checkbox {
            input[type=checkbox] {
                margin-left: 0;
                width: 105px;
            }
        }
    }


}

@media screen and (max-width: 1440px) {
    .error-404 {
        .master-content:not(.home) {
            .page-wrapper {
                margin-top: 14%;
            }
        }


    }

    .form-job-data {
        .left-cnt {

            margin-right: 50px;
            margin-left: 3px;
        }
    }



    .email-subscribe-input {

        width: 555px;

        &.mt-3 {
            margin-top: 25px !important;
        }
    }


    .master-content {
        &:not(.home) {


            .page-wrapper {

                margin-top: 12%;
            }


        }
    }

    .footer-main {
        padding: 1rem 0;
    }

    .infos {
        margin-bottom: 40px;

        .title {
            h2 {
                margin-bottom: 40px;
                font-size: 27px;
                line-height: 34px;
            }
        }
    }

    .backers-wrapper {

        margin-bottom: 4%;
    }

    .email-subscribe-input {
        input {
            height: 3rem;
        }

        .submit {
            height: 3rem;
        }
    }

    .jobs-wrapper {
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 4rem;
    }

    .contact-us-wrapper {
        .consent-checkbox {
            input[type=checkbox] {
                margin-left: 0;
                width: 105px;
            }
        }
    }
}

@media screen and (max-width: 1280px) and (max-height: 700px) {
    .infos {
        margin-bottom: 15px;
    }

    .master-content:not(.home) {
        .page-wrapper {
            margin-top: 13%;
        }
    }
}

@media screen and (min-height:851px) and (minc-width:1000px) {
    .master-content {
        &:not(.home) {
            .page-wrapper {

                margin-top: 12%;

            }
        }

    }

}

@media screen and (max-height: 850px) and (min-width:1000px) {

    /*.hp-text {
        overflow-y: scroll;
        overflow-x: hidden;
        height    : 72vh;
        display   : block;
    }*/

    .claire-logo img {
        width: 6.8rem;
    }

    .header-wrapper {

        margin: 1.5rem auto;

    }

    .master-content {
        /*   &:not(.home) {
            .header-wrapper {

                margin: 1.5rem 10rem 0;

            }
        }*/
    }
}

@media screen and (max-width: 1280px) {
    .contact-us {
        justify-content: space-between;
    }

    .leadership-wrapper {
        .logos {
            span {
                margin-right: 70px;
                font-size: 18.04px;


            }
        }
    }

    .leader-wrapper {

        grid-template-columns: repeat(3, 1fr);

    }

    .backers-wrapper {
        .backer-box {
            width: 450px;
            padding-bottom: 40px;
            padding-right: 50px;
            padding-left: 50px;
        }
    }

    .jobs-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }

    .contact-us-wrapper {
        .consent-checkbox {
            input[type=checkbox] {
                margin-left: 0;
                width: 105px;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .job-details-wrapper {

        .left-side,
        .right-side {
            width: 100%;
        }

        .consent-checkbox {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            input[type=checkbox] {
                margin-left: 0;
            }
        }
    }

    .job-item {

        &:not(:last-child) {
            margin-bottom: 0;
        }
    }

    .contact-us-wrapper {
        .consent-checkbox {
            input[type=checkbox] {
                margin-left: 0;
                width: 105px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .contact-us-wrapper {
        .consent-checkbox {
            input[type=checkbox] {
                margin-left: 0;
                width: 35px;
            }
        }
    }

    .contact-us {
        .contact_form {
            textarea {
                width: 100%;
            }
        }

        .thankyou {
            max-width: 94%;
            margin: 0 auto;


            p {
                font-size: 16px;
                line-height: 25px;
                font-family: 'Manrope-Light';
                margin-bottom: 80px;
            }

            h2 {

                font-size: 28px;
                line-height: 38px;
                margin-top: 80px;

            }
        }

        .submit-form {
            margin-bottom: 40px;

            .primary-cta {
                margin: 0 auto;
            }
        }

        .get_in_touch {

            margin-bottom: 50px;

            h2 {
                font-size: 28px;
                line-height: 38px;
            }

            p {
                font-size: 16px;
                line-height: 25px;
            }
        }

    }

    // if this block will be commented jobs wrapper on "work with us" page will not switch anymore between mobile and desktop view

    .jobs-wrapper {

        width: 80vw;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-box-pack: unset;
        -ms-flex-pack: unset;
        justify-content: unset;
        height: 30vh;

        &.desk-only {
            display: none;
        }

        &.mob-only {

            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }

        .job-title {
            width: 12rem;

        }
    }

    .who-pg {

        h3 {
            font-size: 20px !important;
            line-height: 28px !important;
        }

        .back-description {
            .link-text.color-black {
                white-space: nowrap;
                text-transform: uppercase;
            }

            .desc {
                p {
                    max-width: 180px;
                    text-transform: uppercase;
                    text-align: right;
                }

                span {
                    display: none;
                }
            }
        }

        .block-1 {
            flex-direction: column;
            margin-bottom: 40px;

            h1 {
                margin-top: 65px;
                font-size: 28px;
                line-height: 38px;
                text-align: center;

                &,
                .bold {
                    font-family: 'Manrope-Light';
                    font-weight: 300;
                }
            }

            .right-img img {
                max-width: 300px;
            }

            .left-txt {
                min-height: unset;
                margin-bottom: 40px;
                text-align: center;
            }
        }

        .box-purple {
            width: 96%;
            margin: 0 auto;

            .floating-box,
            .floating-box-bottom {
                position: relative;
                width: 100%;
                top: unset;
                padding: 50px 25px;

                p {
                    text-align: center;
                    line-height: 25px;
                }
            }
        }

        .block-2 {
            margin-bottom: 40px;

            &.digital {
                .box-purple {
                    top: -50px;
                    margin-bottom: -50px;
                }
            }
        }

        .block-3 {
            flex-direction: column;

            .left-img {
                margin-right: 0;

                &.desk-only {
                    display: none;
                }

                &.mob-only {
                    display: block !important;
                }
            }

            .left-text,
            .right-text {
                margin-right: 0;
                text-align: center;

                p {
                    line-height: 25px;
                }
            }

            %text-content-alignment {
                max-width: unset;
                width: 94%;
                margin-left: 4%;
                margin-right: 4%;
            }

            %phone-image-sizes {
                width: 100%;
                max-height: 500px;
            }

            &.health {
                margin-bottom: 40px;

                .desk-only {
                    display: none;
                }

                .mob-only {
                    display: block;
                    width: 100%;

                    margin-bottom: 40px;
                }

                .box .box-desc p {
                    margin-bottom: 10px;
                }
            }

            &.provider {
                .right-text {
                    @extend %text-content-alignment;
                }

                .mob-only {
                    img {
                        @extend %phone-image-sizes;
                    }
                }
            }

            &.digital {
                .left-text {
                    @extend %text-content-alignment;
                }

                .desk-only {
                    display: none;
                }

                .mob-only {
                    img {
                        @extend %phone-image-sizes;
                    }

                    display : block !important;
                }
            }
        }

        .graphic {
            h2 {
                margin-bottom: 30px;
                font-size: 20px;
                line-height: 28px;
            }

            .desk-only {
                display: none;
            }

            .mob-only {
                display: block !important;
                max-height: 1300px;
            }
        }

        .block-4 {
            img {
                width: 100%;
            }

            .floating-box-right {
                position: relative;
                top: unset;
                right: unset;
                width: 96%;
                margin: 0 2%;
                padding: 50px 25px;
                text-align: center;

                p {

                    font-size: 16px;
                    line-height: 22px;
                }
            }

            &.health {
                margin-bottom: 40px;

                .floating-box-right {
                    padding: 50px 25px;

                    h4 {
                        font-weight: 300;
                        font-size: 23px;
                        line-height: 31px;
                    }

                    ul li {
                        font-size: 16px;
                        line-height: 28px;
                        text-transform: capitalize;
                    }
                }
            }

            &.switched {
                .purple-box {
                    width: 100%;

                    .floating-box-left {
                        position: relative;
                        top: unset;
                        padding: 50px 25px;
                        width: 90%;
                        margin-left: 5%;
                        margin-right: 5%;
                        text-align: center;

                        h4 {
                            margin-bottom: 20px;
                            font-weight: 700;
                            font-size: 22px;
                            line-height: 28px;
                            text-transform: uppercase;
                            font-family: 'Manrope-Bold';
                        }

                        .separator {
                            width: 34px;
                            height: 0px;
                            border-top: 1.6px solid #000000;
                            display: block;
                            margin: 0 auto 20px;
                        }
                    }
                }
            }
        }


        .block-5 {
            flex-direction: column;
            align-items: center;
            margin-bottom: 0;

            h4 {
                font-size: 14px;
                line-height: 19px;
            }

            .box {
                margin-right: 0;
                margin-bottom: 80px;
            }

            .title {
                height: auto;
                margin-bottom: 15px;
            }

            .box-desc p {

                font-size: 13px;
                line-height: 119.6%;
            }
        }

    }
}

@media screen and (max-width: 900px) {

    .error-404 {
        .master-content:not(.home) {
            .page-wrapper {
                margin-top: 160px;

                .response-404 {
                    h1 {

                        font-size: 50px;
                        line-height: 70px;

                    }

                    .body-text {
                        font-size: 18px;
                        line-height: 22px;

                    }

                    .link-text {
                        margin-top: 60px;

                        .body-text {
                            font-size: 13px;
                            line-height: 18px;
                        }
                    }

                }
            }
        }


    }

    .unmute-video {
        bottom: 14vh;
    }

    .c-line {
        animation-delay: 13s;
    }

    .c-text {
        animation-delay: 13s;
    }

    .pg-mb {
        &.textual {
            .c-line {
                animation-delay: 0s;
            }

            .c-text {
                animation-delay: 0s;
            }
        }

    }

    .header-main {
        width: 100%;
    }


    .mobile-shadow {

        position: fixed;
        width: 100%;
        height: 193px;
        left: 0px;
        top: 0px;
        background: linear-gradient(180deg, #212121 0%, rgba(33, 33, 33, 0) 100%);
        z-index: 2;
    }

    /*menu section*/


    .desk {
        display: none;
    }

    .mobile {
        display: block;

        &.hide {
            display: none;
        }
    }

    .show-menu {
        -webkit-animation: fadein 1s;
        /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 1s;
        /* Firefox < 16 */
        -ms-animation: fadein 1s;
        /* Internet Explorer */
        -o-animation: fadein 1s;
        /* Opera < 12.1 */
        animation: fadein 1s;
    }


    .infos {
        .text {
            display: block;
            text-align: left;
            font-size: 16px;
            line-height: 25px;
        }

    }




    .claire-logo-m {

        &.mobile {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
        }

        img.menu-logo {
            width: 28px;
            height: 12px;
        }

        img.hp-logo {
            max-width: 125px;
        }

        img.page-logo {
            max-width: 125px;
        }
    }

    .menu {
        &.mobile {

            background: $blue;
            position: fixed;
            top: 0;
            z-index: 9;
            width: 100%;
            height: 100%;

        }

        img.close-icon {
            position: absolute;
            top: 80px;
            right: 45px;
        }

        .menu-item {
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            height: inherit;

            .link-text {
                margin-bottom: 30px;
                font-size: 20.25px;
                line-height: 28px;
                font-family: 'Manrope-Light';

                user-select: none;

                &:hover {
                    color: white;
                }

                &:focus-visible {
                    outline: none;
                }
            }
        }
    }

    .master-content:not(.home) {
        .page-wrapper {
            margin-top: 159px;
        }
    }


    .header-cnt {
        position: relative;
        height: 100%;
        width: 100%;

    }

    .header-normal {
        width: 100%;
    }

    .header-wrapper {
        margin: 3.5rem 2.25rem 0;
        width: calc(100% - 2.25rem - 2.25rem);
    }

    .claire-logo img {
        width: 7.75rem;
    }

    /*hp*/
    .sub-section2 {

        padding: 0 38px;

        .hp-text {
            display: block !important;
            margin-top: 94px;
            margin-bottom: 25px;

            .body-text {
                text-align: left;
                font-size: 16px !important;
                line-height: 25px !important;
            }

        }
    }



    .infos {
        display: block !important;
        margin-bottom: 20px;

        .title {
            h2 {
                font-size: 28.83px;
                line-height: 39px;
                margin-bottom: 21px;
                text-align: left;


                br {
                    display: none;
                }
            }
        }
    }

    .email-subscribe-input {
        &.mt-3 {
            margin-top: 50px !important;
        }
    }



    .medium-title {
        font-size: 28.83px !important;
        line-height: 39px;
    }

    body.hp2-pg .master-content .coming-spring.medium-title {
        font-size: 18px !important;
        line-height: 24px;
        margin-bottom: 10px;
    }

    .coming-soon-wrapper {
        width: 100%;
    }

    /*.hp-text {

        height: 60vh;

    }*/

    .backers {
        text-align: left;

        .big-title-light {
            text-align: left;

        }

        .h100 {
            align-items: flex-start;
            width: 100%;
        }

        .body-text {
            font-size: 16px;
            line-height: 25px;
            text-align: left;
        }


    }


    .big-title-light {
        font-size: 28.83px;
        line-height: 39px;

    }

    .master-content:not(.home) {

        .header-normal,
        .header-cnt {
            height: 140px;
            background: linear-gradient(to bottom,
                    hsl(210, 100%, 97%) 0%,
                    hsl(210, 100%, 97%) 75%,
                    hsla(210, 100%, 97%, 0.998) 76%,
                    hsla(210, 100%, 97%, 0.992) 77%,
                    hsla(210, 100%, 97%, 0.981) 78%,
                    hsla(210, 100%, 97%, 0.963) 79%,
                    hsla(210, 100%, 97%, 0.936) 80%,
                    hsla(210, 100%, 97%, 0.898) 81%,
                    hsla(210, 100%, 97%, 0.848) 83%,
                    hsla(210, 100%, 97%, 0.784) 85%,
                    hsla(210, 100%, 97%, 0.704) 87%,
                    hsla(210, 100%, 97%, 0.606) 89%,
                    hsla(210, 100%, 97%, 0.488) 91%,
                    hsla(210, 100%, 97%, 0.349) 94%,
                    hsla(210, 100%, 97%, 0.187) 97%,
                    hsla(210, 100%, 97%, 0) 100%);
        }
    }

    /*backers*/
    .backers-wrapper {
        overflow-x: scroll;
        overflow-y: hidden;
        max-width: 100%;

        margin-bottom: 0px;
        margin-top: 40px;
        padding-right: 4px;
        margin-bottom: 70px;
        height: 450px;

        &.mob-only {
            &.d-flex {
                display: flex !important;
            }

        }

        &.desk-only {
            &.d-flex {
                display: none !important;
            }

        }

        .backer-box {
            width: 99%;
            min-width: 99%;
            padding-bottom: 40px;
            padding-right: 35px;
            padding-left: 35px;
            text-align: left;
            margin-right: 2px;

            .liveperson {
                width: 170px;

                img {
                    width: 100%;
                }
            }

            .view-backers-cta {
                left: 37px;
                transform: translate(0%);
            }
        }


        .body-text-light {

            font-size: 14.22px;
            line-height: 20px;
        }
    }

    .mobile-scroll {
        display: block;

        margin: 0 auto;
        margin-bottom: 75px;

        .body-text {
            font-size: 14.22px;
            line-height: 20px;
        }

        .scroll-bar {
            width: 15px;
            border: 1px solid black;
            height: 1px;
            display: block;
            margin-left: -15px;
            margin-top: 11px;
        }
    }



    /*leaders*/
    .leader-detail {
        .big-title-light {
            margin-top: 60px;
            opacity: 0;

        }

        .logos {
            display: block;
            width: auto;

            margin-top: 50px;
            margin-bottom: 80px;
            //flex-flow    : column;

            span {
                font-size: 18.04px;
                line-height: 26px;
                padding: 0;
                margin-bottom: 20px;
                margin-right: 20px;
                margin-left: 20px;
            }

            .purple-separator {
                display: none;
            }
        }

        .h100 {
            //align-items: flex-start;
            display: block;

            .body-text {
                text-align: left;
                margin-top: 20px !important;
            }
        }

        .leader-detail-inner {
            &.d-flex {
                flex-flow: column;
            }

            img {
                width: 216px;
                margin: 0 auto 40px;
                margin-top: 0 !important;
                padding-left: 0;
            }

            .lead-infos {
                margin-left: 0;

                .leader-name {
                    margin-bottom: 36px;

                    .medium-title {
                        margin-top: 20px;
                    }
                }

                .lead-detail-desc {
                    border-left: none;
                    padding-left: 0px;
                }

                .leader-name {
                    text-align: center;
                }
            }
        }
    }

    .leadership {

        align-items: flex-start;


        .big-title-light {
            text-align: left;

        }

        .body-text {
            text-align: left;

            &.title-wrapper {
                margin-top: 20px !important;
            }
        }
    }

    /* .leader-wrapper::-webkit-scrollbar {
        -webkit-appearance: none;
        width             : 7px;
        height            : 7px;
    }

    .leader-wrapper::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.03);
    }

    .leader-wrapper::-webkit-scrollbar:horizontal {
        height: 3px;

    }

    .leader-wrapper::-webkit-scrollbar-thumb {


        height       : 6px;
        background   : linear-gradient(94.94deg, #A347FF 0%, #432CD2 100%);
        border-radius: 10px;
    }
*/


    /*  .backers-wrapper::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    .backers-wrapper::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.03);
    }

    .backers-wrapper::-webkit-scrollbar:horizontal {
        height: 3px;

    }

    .backers-wrapper::-webkit-scrollbar-thumb {


        height       : 6px;
        background   : linear-gradient(94.94deg, #A347FF 0%, #432CD2 100%);
        border-radius: 10px;
    }*/


    /* .jobs-wrapper::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    .jobs-wrapper::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.03);
    }

    .jobs-wrapper::-webkit-scrollbar:horizontal {
        height: 3px;

    }

    .jobs-wrapper::-webkit-scrollbar-thumb {


        height       : 6px;
        background   : linear-gradient(94.94deg, #A347FF 0%, #432CD2 100%);
        border-radius: 10px;
    }*/


    .leader-wrapper {

        width: 80vw;
        justify-content: center;
        overflow-x: scroll;
        overflow-y: hidden;
        justify-content: unset;
        /* height         : 52vh;*/
        height: 380px;
        margin: 30px 0;
        margin-bottom: 55px;

        &.desk-only {
            display: none;
        }

        &.mob-only {
            display: flex;
        }


        .leader-cnt {
            /* height: 43vh;*/
            height: 328px;
            min-width: 215px;
            width: 215px;
            padding-top: 10px;

            .leader-logo {
                img {
                    margin-bottom: 22px;
                    -webkit-filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
                    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
                }
            }
        }
    }

    /*job*/


    .email-subscribe-input {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        input {
            height: 3.7rem;
            font-size: 0.89rem;
            line-height: 20px;
            padding-left: 1.62rem;
        }

        .submit {
            position: relative;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
            top: 0;
            height: 3.7rem;
            margin-top: 1.25rem;
            width: 11.6rem;
            font-size: 1rem;
        }
    }

    .border-left {
        padding-left: 13px;
    }

    .consent-checkbox {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        margin-top: 50px;

        .body-text-small {
            font-size: 14.22px;
            line-height: 20px;
        }

        label {
            width: 80%;
        }

        input[type='checkbox'] {
            margin-left: 1rem;
        }
    }

    .footer-main {
        padding: 1rem 0;

        flex-flow: column-reverse;
        align-items: flex-start;



        .body-text,
        .footer-links a {
            font-size: 12px !important;
            line-height: 18px !important;

            br {
                display: block;
            }
        }

        .footer-links {
            margin-bottom: 0;
            text-align: right;

            a {
                white-space: nowrap;
            }
        }

        .body-text {
            margin-top: 9px;

            span.reserved {
                margin-left: 0px;
            }
        }
    }

    .footer-links {
        margin-bottom: 0.5rem;
        width: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .link-text {
            font-size: 12.64px;
            line-height: 17px;
        }

        &>a:not(:first-child) {
            margin-left: 0;
        }
    }

    .backers-pg,
    .leaderships-pg,
    .work-pg {
        .master-content .page-wrapper {
            %boxed_layout {
                margin-left: 10%;
                margin-right: 10%;
            }

            width : 100%;

            .title-wrapper {
                width: 80%;
            }

            h2.big-title-light,
            p.body-text {
                @extend %boxed_layout;
            }

            .backers-wrapper.mob-only,
            .leader-wrapper.mob-only,
            .jobs-wrapper.mob-only {
                width: 100vw;

                .backer-box,
                .leader-cnt,
                .job-item {
                    &:not(:last-child) {
                        margin-left: 1rem;
                    }

                    &:first-child {
                        margin-left: 10%;
                    }
                }

                .backer-box {
                    width: 80%;
                    min-width: 80%;
                }
            }

            .backers-wrapper.mob-only .simplebar-content {
                margin-bottom: 1px;
            }

            .job-details-wrapper {
                @extend %boxed_layout;
            }
        }
    }

    .leaderships-pg .leader-wrapper.mob-only .simplebar-offset::after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 100%;
        background: linear-gradient(90deg, rgba(245, 250, 255, 0) 0%, #F1F8FF 89.07%);
    }

    .work-pg {
        .right-side {
            margin-top: 34px;
        }

        .left-side {
            margin-bottom: 100px;
        }

        .desk-job {
            display: none;
        }

        .mob-job {
            display: block;
        }

        .h100 {
            align-items: flex-start;

            &.title-work {
                width: 100%;

                .body-text {
                    text-align: left;
                    margin-top: 20px !important;
                }

                .medium-title {
                    font-size: 25.63px;
                    line-height: 35px;
                }
            }

            .back-cta {
                font-size: 13px;
                line-height: 18px;
                font-family: 'Manrope-SemiBold', sans-serif;
            }

            .big-title-light {
                text-align: center;
            }

            .head-title {
                flex-flow: column;
                align-items: center;
            }

        }

        .job-details-wrapper .left-side .accordion-button::after {
            margin-right: 5px;
        }

        .works-categories {
            flex-flow: column;

            span.top-desc {
                margin-top: 11px;
                text-align: center;
                font-size: 13.3px;
                line-height: 18px;
                margin-bottom: 28px;
            }

            .primary-cta {
                width: fit-content;
                padding-top: 5px;
                padding-bottom: 5px;
                margin-bottom: 0;




                span {
                    font-size: 14.22px;
                    line-height: 20px;

                }
            }
        }

        .company-details {
            &.body-text {
                font-size: 12.64px;
                line-height: 17px;
            }
        }

        .accordion {
            margin-top: 3rem !important;
        }

        .job-details-wrapper {
            .left-side {
                .accordion-header {
                    font-size: 18px;
                    line-height: 25px;
                }

                .accordion-body {
                    font-size: 14.22px;
                    line-height: 20px;
                    margin-top: 0;

                    .mb-3 {
                        margin-bottom: 24px !important;
                    }
                }
            }
        }


        .selection {
            width: 100%;
            padding: 14.17px 30px;
            font-size: 16px;
            line-height: 25px;

            &:focus-visible {

                outline: none;
            }
        }

        #apply-now {
            margin-top: 80px;




            .medium-title-light {
                font-size: 22.78px;
                line-height: 31px;
            }

            .form-job-data {
                flex-flow: column;

                .left-cnt {
                    width: 100%;
                    margin-right: 0;
                    margin-top: 30px !important;
                    margin-left: 0;
                }

                .resume-csv {
                    margin-bottom: 50px;
                }
            }
        }

        .input-wrapper {
            input {
                height: 3.15rem;
            }

            &.mt-15 {
                margin-top: 1.3rem !important;
            }
        }

        .consent-txt {
            font-size: 13.3px;
            line-height: 18px;
            margin-top: 30px !important;
        }

        .consent-checkbox {
            margin-top: 0;
        }

    }

    .error_msg {

        font-size: 14px;
        line-height: 20px;

    }

    .result_msg {

        font-size: 14px;
        line-height: 20px;

    }

    .terms {

        margin-bottom: 80px;

        .terms-cnt {
            width: 100%;
            margin: 0 auto;

            .accordion-item {

                margin: 0;
            }

            .accordion-button::after {
                margin-right: 4px;
            }

            .accordion-header {
                font-size: 18px;
                line-height: 25px;
            }
        }
    }

    .jobs-wrapper {
        width: 100%;
        height: 250px;
        margin: 2rem 0 38px;

        .job-item {
            margin-left: 1rem;
        }
    }

    .title-wrapper {
        width: 100%;
    }

    .shadow {
        width: 80%;
        height: 28%;
    }

    .intro-text-desk {
        display: none;
    }

    .intro-text-mobile {
        display: block;
    }

    body.hp2-pg {
        .swiper {
            display: none;
        }

        .pg-mb {
            position: relative;

            &.textual {
                .c-scrolldown {
                    position: relative;
                }
            }

            .unmute-video {
                position: absolute;
            }

        }

        .master-content {

            .header-normal,
            .header-cnt {

                background: linear-gradient(180deg, #D5DDEB 55.1%, rgba(213, 221, 235, 0) 100%);
                height: 190px;
                z-index: 999;
                position: fixed;
                top: 0;
            }

            .page-wrapper {
                display: table;
            }

            .slide3 {
                margin-bottom: 0;
            }

            .mobile-hp2 {
                display: block;
            }

            .result_msg {
                padding-top: 20px;
            }

            .footer-wrapper {
                display: block;

                position: relative;
                bottom: 0;
                left: 0;
                transform: translate(0%);

                background: none;

                .body-text {
                    font-size: 12.64px;
                    line-height: 20px;
                }

                .link-text {
                    font-size: 12.64px;
                    line-height: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .dark-background {
        background-image: url('../img/jpg/dark-background-mobile.jpg');
    }

    .leader-detail .logos {
        flex-flow: column;
    }

    .contact-us {
        .consent-checkbox input[type=checkbox] {
            width: 35px !important;
        }
    }
}

@media screen and (max-width:300px) {
    .backers-wrapper {
        height: 550px;

    }
}





















[data-simplebar] {
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative
}

.simplebar-wrapper {
    height: inherit;
    max-height: inherit;
    max-width: inherit;
    overflow: hidden;
    width: inherit
}

.simplebar-mask {
    direction: inherit;
    height: auto !important;
    overflow: hidden;
    width: auto !important;
    z-index: 0
}

.simplebar-mask,
.simplebar-offset {
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0
}

.simplebar-offset {
    -webkit-overflow-scrolling: touch;
    box-sizing: inherit !important;
    direction: inherit !important;
    resize: none !important
}

.simplebar-content-wrapper {
    -ms-overflow-style: none;
    box-sizing: border-box !important;
    direction: inherit;
    display: block;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    overflow: auto;
    position: relative;
    scrollbar-width: none;
    width: auto
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0
}

.simplebar-content:after,
.simplebar-content:before {
    content: ' ';
    display: table
}

.simplebar-content {
    display: flex;
}

.simplebar-placeholder {
    max-height: 100%;
    max-width: 100%;
    pointer-events: none;
    width: 100%
}

.simplebar-height-auto-observer-wrapper {
    box-sizing: inherit !important;
    flex-basis: 0;
    flex-grow: inherit;
    flex-shrink: 0;
    float: left;
    height: 100%;
    margin: 0;
    max-height: 1px;
    max-width: 1px;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: relative;
    width: 100%;
    z-index: -1
}

.simplebar-height-auto-observer {
    box-sizing: inherit;
    display: block;
    height: 1000%;
    left: 0;
    min-height: 1px;
    min-width: 1px;
    opacity: 0;
    top: 0;
    width: 1000%;
    z-index: -1
}

.simplebar-height-auto-observer,
.simplebar-track {
    overflow: hidden;
    pointer-events: none;
    position: absolute
}

.simplebar-track {
    bottom: 0;
    right: 0;
    z-index: 1
}

[data-simplebar].simplebar-dragging,
[data-simplebar].simplebar-dragging .simplebar-content {
    -webkit-touch-callout: none;
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none
}

[data-simplebar].simplebar-dragging .simplebar-track {
    pointer-events: all
}

.simplebar-scrollbar {
    left: 0;
    min-height: 10px;
    position: absolute;
    right: 0
}

.simplebar-scrollbar:before {

    border-radius: 7px;
    content: '';
    opacity: 1;
    position: absolute;
    transition: opacity .2s linear .5s;
    background: linear-gradient(94.94deg, #A347FF 0%, #432CD2 100%);
}

.simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
    transition-delay: 0s;
    transition-duration: 0s
}

.simplebar-track.simplebar-vertical {
    top: 0;
    width: 11px;
    display: none;

}

.simplebar-scrollbar:before {
    bottom: 2px;
    left: 2px;
    right: 2px;
    top: 2px
}

.simplebar-track.simplebar-horizontal {
    height: 6px;
    left: 0;
    background: rgba(0, 0, 0, 0.03);
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    bottom: 0;
    left: 0;
    min-height: 0;
    min-width: 10px;
    right: auto;
    top: 0;
    width: auto
}

[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
    left: 0;
    right: auto
}

.simplebar-dummy-scrollbar-size {
    -ms-overflow-style: scrollbar !important;
    direction: rtl;
    height: 500px;
    opacity: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    position: fixed;
    visibility: hidden;
    width: 500px
}

.simplebar-dummy-scrollbar-size>div {
    height: 200%;
    margin: 10px 0;
    width: 200%
}

.simplebar-hide-scrollbar {
    -ms-overflow-style: none;
    left: 0;
    overflow-y: scroll;
    position: fixed;
    scrollbar-width: none;
    visibility: hidden
}

.list-styles {
    //list-style: inside !important;
    margin-left: 20px;
    list-style-type: disc !important;
    padding-left: 1em;
}